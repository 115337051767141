<template>
  <div>
    <div v-if="receivedMessage">
      <h2>Received Msg:</h2>
      <p>{{ receivedMessage }}</p>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueMqtt from "vue-mqtt";
import { mapGetters } from "vuex";

const moment = require("moment");

Vue.use(VueMqtt, "wss://mqtt.flespi.io:443/", {
  clientId: "WebClient-" + parseInt(Math.random() * 100000),
  username: "H0i9Oy5cefXRGGdfNLZLpBt3s7h2h1aPRgOuDIuHLhniLonnXbWaCf3SbaJiZJVE",
});

export default {
  data() {
    return {
      topic: "flespi/message/gw/devices/5726057", // Replace with your topic
      message: "",
      receivedMessage: "",
      receivedMessages: [],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      vehicleList: "vehicles/getVehiclesList",
    }),

    topics() {
      return this.vehicleList.map(
        (r) => `flespi/message/gw/devices/${r.device_id}`
      );
    },
  },
  created() {
    this.subscribeToTopics();
    // this.publish();
  },
  beforeDestroy() {
    // Optionally, you can unsubscribe from topics if needed
    // this.$mqtt.unsubscribe(this.topic);
    this.unsubscribeFromTopics();
  },
  methods: {
    subscribeToTopics() {
      this.topics.forEach((topic) => {
        this.$mqtt.subscribe(topic);
      });
    },
    unsubscribeFromTopics() {
      this.topics.forEach((topic) => {
        this.$mqtt.unsubscribe(topic);
      });
    },
    // publish() {
    //   //   this.$mqtt.publish("VueMqtt/publish1", "message to Sub1");
    // },
    // sendMessage() {
    //   if (this.message) {
    //     this.$mqtt.publish(this.topic, this.message, (err) => {
    //
    //     });
    //   }
    // },
  },
  mqtt: {
    // Subscribe to messages on VueMqtt/publish1
    // "VueMqtt/publish1"(data, topic) {
    //   this.receivedMessage = new TextDecoder().decode(new Uint8Array(data));
    // },
    // // Subscribe to messages on the defined topic
    // "flespi/message/gw/devices/5726057"(data) {
    //   this.receivedMessage = new TextDecoder().decode(new Uint8Array(data));
    // },
    // Handle messages from any topic
    "flespi/message/gw/devices/#"(data, topic) {
      // Wildcard to capture all topics
      const msg = JSON.parse(new TextDecoder().decode(new Uint8Array(data)));
      const { ident, position_latitude, position_longitude, server_timestamp } =
        msg;
      const params = {
        ident: ident,
        topic: topic,
        lat: position_latitude,
        lng: position_longitude,
        // time: server_timestamp,
      };

      const d = parseFloat(server_timestamp);
      const dd = moment(d * 1000);
      const formattedDate = dd.format("YYYY-MM-DD HH:mm:ss"); //2024-09-13 20:21:30
      params.time = formattedDate;
      this.$emit("stream", params);
    },
  },
};
</script>
